.tablediv{
    width: auto;
    display: block;
    margin-left: 50px;
    margin-right: 50px;
    justify-content: center;
    align-items: center;
    color: white;
}

.bold{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 500;
    background-color: #a8a8a8;
}

.table{
    width: auto;
    margin: 100 auto;
    text-align: center;
    color: white;
    box-shadow: #000000 0px 0px 10px 0px;
}

.tablehead{
    background-color: #d1d1d13f;
    font-weight: bold;
    color: rgb(0, 0, 0);
    width: auto;
    text-align: center;
    align-items: center;
}

.tablerow{
    background-color: #d1d1d13f;
    color: white;
    width: auto;
    text-align: center;
    align-items: center;
}
.tablecell{
    margin: 0 auto;
    text-align: center;
    align-items: center;
    width: auto;
    color: rgb(0, 0, 0);
    border: 1px solid rgb(255, 255, 255);
}
