html,
body {
    background-color: #ffffff;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    min-height: 100vh;
}

.navbar {
    height: 65px;
    background-color: #006eff;
    color: white;
    box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
    position: fixed;
    width: 100%;
    z-index: 998;
}

.navlogo{
    text-decoration: none;  
    color: white;  
}

.navlogo-img{
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 0 25px 0 rgba(104, 104, 104, 0.2);
    top: 7px;
    left: 20px;
    position: fixed;
}
.navlogo-img:hover{
    transform:scale(1.1);
    transform: rotate(360deg);
    transition: all 0.3s ease-in-out;
}


.navtext {
    position: fixed;
    font-size: 18px;
    margin-top: 20px;
    margin-left: 80px;
}


.dash-avatarr {
    position: fixed;
    top: 7px;
    right: 20px;
    z-index: 999;
}

.dash-btn {
    position: fixed;
    top: 13px;
    right: 20px;
    z-index: 999;
}

.dash-btn-left {
    position: fixed;
    top: 13px;
    right: 65px;
    z-index: 999;
}



.my-button {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.my-button:hover {
    background-color: #45a049;
  }

.blood-logo{
    position: fixed;
    top:6px;
    left:1250px;
}