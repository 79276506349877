.center{
    position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
}
.login-form-main-div{
    background-color: #ececec9d;
    height: 400px;
    width: 400px;
    border-radius: 25px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
}


.signup-form-main-div{
    background-color: #ececec9d;
    height: 400px;
    width: 900px;
    border-radius: 25px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
}


.login-text-box-main{
    padding: 10px;

}
.login-text-box{
    width: 300px;
}


.login-page-button{
    width: 300px;
    height: 50px;
}

.wanttosignup{
    color: rgb(201, 0, 0);
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
}

.wanttosignup:hover{
    color: rgb(0, 110, 255);
    transition: all 0.3s ease-in-out;
}

.wanttosignup:active{
    color: rgb(0, 110, 255);
    transition: all 0.3s ease-in-out;
}




/* transition test */
.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }