.preloader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f5f549;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 72px;
    height: 72px;
    display: grid;
    border: 5.8px solid #0000;
    border-radius: 50%;
    border-color: #9d9d9d #0000;
    animation: spinner-e04l1k 0.8s infinite linear;
 }
 
 .spinner::before,
 .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2.9px;
    border: inherit;
    border-radius: 50%;
 }
 
 .spinner::before {
    border-color: #006eff #0000;
    animation: inherit;
    animation-duration: 0.4s;
    animation-direction: reverse;
 }
 
 .spinner::after {
    margin: 11.6px;
 }
 
 @keyframes spinner-e04l1k {
    100% {
       transform: rotate(1turn);
    }
 }